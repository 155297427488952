import React from 'react';
import blogDefaultCover from '../images/blog/blog-default-cover.png'
import BlogSidebar from '../components/blogSidebar'
import {Link, graphql} from "gatsby"
import {Helmet} from "react-helmet";

const BlogDirectory = ({data, pageContext: {next, previous}}) => {
    return (
        <div>
            <Helmet>
                <title>Tron DAO Blog | TRONDAO</title>
            </Helmet>
            <div className="blog-directory-header">
                    <h1 className="blog-directory-header-title text-center">BLOG</h1>
            </div>
            <div className="mx-auto" style={{maxWidth: "1920px"}}>
                <div className="">
                    <div className="blog-wrap">
                        <div className="blog-directory pt-2">
                            {
                                data.allWpPost.edges.map(({node}, index) => {
                                    return (
                                        <div key={node.id + '-' + index}>
                                            <BlogItem node={node} key={node.id}/>

                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <BlogSidebar/>
                        </div>
                    </div>
                </div>
                <div className="container pb-5">
                    <div className="blog-post-pagination position-relative pb-5">
                        {
                            previous &&
                            <Link to={`${previous}`}
                                  className="blog-post-pagination-previous ml-0 ml-md-3">{'< Previous'}</Link>
                        }
                        {
                            next &&
                            <Link to={`${next}`} className="blog-post-pagination-next mr-0 mr-lg-3">{'Next >'}</Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const BlogItem = ({node: {title, uri, date, author, excerpt, featuredImage}}) => {
    let coverImg = (featuredImage) ? featuredImage.node.sourceUrl : blogDefaultCover;
    return (
        <a className="px-0 col-12 blog-item text-roboto row no-gutters" href={`/blog${uri}`}>
            <div className="blog-img-wrap col-md-4 col-12 d-flex align-items-center">
                <img className="img-fluid p-3" src={coverImg} alt={title}/>
            </div>
            <div className="col-md-8 col-12">
                <h3 className="blog-item-title">{title}</h3>
                {/* <p className="text-blue">{date} - {author.node.name}</p> */}
                <p className="text-blue">{date}</p>
                <div className="mt-3 blog-item-excerpt text-roboto-light" dangerouslySetInnerHTML={{__html: excerpt}}/>
                <Link to={`/blog${uri}`} className="text-magenta">
                    Read more >
                </Link>
            </div>
        </a>
    )
}

export default BlogDirectory

export const pageQuery = graphql`
query ($limit: Int!, $skip: Int!) {
  allWpPost(limit: $limit, skip: $skip, sort: {order: DESC, fields: date}) {
    edges {
      node {
        id
        uri
        title
        content
        date(formatString: "MMMM DD, YYYY")
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
        author {
          node {
            name
          }
        }
      }
    }
  }
}
`